import { TwitterModule } from './twitter/twitter.module';

// import { AddLog } from './shared/component/changelogadmin/add-log-admin';
import { FirebaseTransLoader } from './shared/interceptors/fire-translate.loader';
import { HomeMobileComponent } from './home/mobile/home-mobile.component';
import { HomePcComponent } from './home/pc/home-pc.component';
import {
  AngularFireDatabase,
  AngularFireDatabaseModule
} from '@angular/fire/compat/database';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { RegistrationComponent } from './registration/registration.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { NutrifoodModule } from './nutrifood/nutrifood.module';
import { BrowserModule } from "@angular/platform-browser";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
//import { NgxCarouselModule } from 'ngx-carousel';
//import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './routing';
import { WeatherModule } from './weather/weather.module';
import { LivestockModule } from './livestock/livestock.module';
import { SharedModule } from './shared/shared.module';
import { AgrimarketModule } from './agrimarket/agrimarket.module';
import { HomeComponent } from './home/home.component';
//import { NguiStickyModule } from '@ngui/sticky';
import { AngularFireModule } from '@angular/fire/compat';
// import { NotificationsComponent } from './notifications/notifications.component';
// import { NotificationDetailsComponent } from './notifications/notification-details/notification-details.component';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HealthyNutritionModule } from './healthy-nutrition/healthy-nutrition.module';
import { HouseholdPoultryModule } from './household-poultry/household-poultry.module';
import { DateProductionModule } from './date-production/date-production.module';
import { CitrusModule } from './citrus/citrus.module';
import { Covid19FoodSafetyModule } from './covid19-food-safety/covid19-food-safety.module';
import { NotificationsModule } from './notifications/notifications.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PlantPestsComponent } from './plant-pests/plant-pests.component';
import { MessageComponent } from './message/message.component';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { ForumModule } from './forum/forum.module';
import { TeamListModule } from './team-list/team-list.module';
import { CovidModule } from "./covid/covid.module";
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { Ng2TelInputModule } from 'ng2-tel-input';

import { SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { AnimalFeedModule } from './animal-feed/animal-feed.module';

export function FbTransLoaderFactory(db: AngularFireDatabase) {
  return new FirebaseTransLoader(db);
}
import { /* FormsModule,  */ReactiveFormsModule } from '@angular/forms';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

// const firebaseUiAuthConfig: FirebaseUIAuthConfig = {
//   providers: [AuthProvider.Phone],
//   method: AuthMethods.Popup,
//   credentialHelper: CredentialHelper.None
// };

/*const config = {
  // apiKey: "AIzaSyBdUZF2qMDKbCbnXJF3sXtS9_4p3n8OxJo",
  // authDomain: "fao-digital-services-portfolio.firebaseapp.com",
  // databaseURL: "https://fao-digital-services-portfolio.firebaseio.com",
  // projectId: "fao-digital-services-portfolio",
  // storageBucket: "fao-digital-services-portfolio.appspot.com",
  // messagingSenderId: "95727839878",
  // appId: "1:95727839878:web:33c4d58033b34d37"

  // apiKey: 'AIzaSyBbCspWE94AfRJnYI7C7dqSa326f1IRnss',
  // authDomain: 'fao-digital-services-portfolio.firebaseapp.com',
  // databaseURL: 'https://fao-digital-services-portfolio.firebaseio.com',
  // projectId: 'fao-digital-services-portfolio',
  // storageBucket: 'fao-digital-services-portfolio.appspot.com',
  // messagingSenderId: '95727839878'

  apiKey: "AIzaSyAg-YFjrlc1FH-4nnGw2FFaf526cSBoME4",
  authDomain: "fao-portfolio-dev.firebaseapp.com",
  databaseURL: "https://fao-portfolio-dev.firebaseio.com",
  projectId: "fao-portfolio-dev",
  storageBucket: "fao-portfolio-dev.appspot.com",
  messagingSenderId: "124577118113",
  appId: "1:124577118113:web:dcbaa7701a3994c2287de0"
};*/

/* const config = {
  apiKey: "AIzaSyAg-YFjrlc1FH-4nnGw2FFaf526cSBoME4",
    authDomain: "fao-portfolio-dev.firebaseapp.com",
    databaseURL: "https://fao-portfolio-dev.firebaseio.com",
    projectId: "fao-portfolio-dev",
    storageBucket: "fao-portfolio-dev.appspot.com",
    messagingSenderId: "124577118113"
}; */

import { DatePipe } from '@angular/common';
import { TwitterComponent } from './twitter/twitter.component';
import { PhoneLoginComponent } from './phone-login/phone-login.component';
import { EmailLoginComponent } from './email-login/email-login.component';
import { AppPreviewModule } from './app-preview/app-preview.module';
import { DownloadOfflineModeDialogComponent } from './offline-support/download-offline-mode-dialog/download-offline-mode-dialog.component';
import { OfflineModeDialogComponent } from './offline-support/offline-mode-dialog/offline-mode-dialog.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { ChatComponent } from './chat/chat.component';
import { InsectModule } from './insect/insect.module';
import { PandemicReportComponent } from './pandemic-report/pandemic-report.component';
import { FamilyPracitesModule } from './family-practices/family-practices.module';

//firebse config
const config = {
  apiKey: environment.config.apiKey,
  authDomain: environment.config.authDomain,
  databaseURL: environment.config.databaseURL,
  projectId: environment.config.projectId,
  storageBucket: environment.config.storageBucket,
  messagingSenderId: environment.config.messagingSenderId,
};

@NgModule({
  // Importing the components that i have created so that angular see them
  declarations: [
    AppComponent,
    HomeComponent,
    HomePcComponent,
    HomeMobileComponent,
    UserProfileComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    RegistrationComponent,
    // NotificationsComponent,
    // NotificationDetailsComponent,
    LoginComponent,
    DisclaimerComponent,
    PlantPestsComponent,
    MessageComponent,
    SelectLanguageComponent,
    PhoneLoginComponent,
    EmailLoginComponent,
    DownloadOfflineModeDialogComponent,
    OfflineModeDialogComponent,
    FeedbackComponent,
    ChatComponent,
    PandemicReportComponent
    // TranslatePipe
  ],
  entryComponents: [],
  // Importing modules
  imports: [
    //FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    //CommonModule,
    MatFormFieldModule, /* MatAutocompleteModule, */ MatButtonModule, MatButtonToggleModule, MatCardModule,
    MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule,
    MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule,
    MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule,
    MatRadioModule, MatRippleModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule,
    MatSnackBarModule, MatSortModule, MatStepperModule, MatTooltipModule,
    MatToolbarModule, MatBottomSheetModule, Ng2TelInputModule,
    OverlayModule,
    //NgxCarouselModule,
    RouterModule,
    AngularFireModule.initializeApp(environment.config),
    AngularFirestoreModule.enablePersistence(),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    //NguiStickyModule,
    WeatherModule,
    LivestockModule,
    AgrimarketModule,
    NutrifoodModule,
    CovidModule,
    AppRoutingModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      //apiKey: 'AIzaSyCa_nfFi-KBZtfRwtCsRCfJm_u6xXzwZbU'
      apiKey: environment.geoLocationApiKey
    }),
    //FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    // Latest code commenting
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FbTransLoaderFactory,
        deps: [AngularFireDatabase]
      }
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    HealthyNutritionModule,
    HouseholdPoultryModule,
    DateProductionModule, CitrusModule, Covid19FoodSafetyModule,
    ForumModule, TeamListModule,
    CovidModule, TwitterModule, NotificationsModule, SwiperModule,
    AppPreviewModule,
    AnimalFeedModule,
    FamilyPracitesModule,
    NgImageFullscreenViewModule,
    InsectModule
  ],
  exports: [MatSidenavModule, MatRippleModule],
  providers: [
    AngularFirestore,
    DatePipe,
    // TranslationService
    NotificationsModule,
    ForumModule,
    CovidModule,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: FIRESTORE_SETTINGS,
      useValue: environment.config.useEmulators
        ? { host: 'localhost:8080', ssl: false }
        : {},
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
